<div class='container'>
  <div class='grabber'>
    <p>grabber goes here</p>
  </div>
  <div class='line'>
    <img src='../../../assets/me.jpg'>
    <div>
      <p>about me 1</p>
    </div>
  </div>
  <div class='line'>
    <div>
      <p>about me 2</p>
    </div>
    <img src='../../../assets/Me-n-roomie.jpg'>
  </div>
  <div class='line'>
    <img src='../../../assets/me-in-ireland.jpg'>
    <div>
      <p>about me 3</p>
    </div>
  </div>
</div>
