<div>
  <nav>
    <a href='https://www.youtube.com/macgyver2141' target='_blank' id='youtube'>
      <fa-icon icon='youtube' size='lg'></fa-icon>
    </a>
    <a href='https://www.twitch.tv/damianalabaster' target='_blank' id='twitch'>
      <fa-icon icon='twitch' size='lg'></fa-icon>
    </a>
    <a href='https://www.twitter.com/macgyver_made' target='_blank' id='twitter'>
      <fa-icon icon='twitter' size='lg'></fa-icon>
    </a>
    <a href='https://discord.gg/uBEZn7G' target='_blank' id='discord'>
      <fa-icon icon='discord' size='lg'></fa-icon>
    </a>
    <a href='https://www.linkedin.com/in/leif-macgyver-swenson' target='_blank' id='linkedin'>
      <fa-icon icon='linkedin' size='lg'></fa-icon>
    </a>
    <a href='mailto:leif@macgyvermade.solutions' target='_blank' id='email'>
      <fa-icon [icon]="['fas', 'envelope']" size='lg'></fa-icon>
    </a>
  </nav>
</div>
