<div class='nav-banner' [class.homePage]='isAtHome' [@toggleBackground]='isAtTop'>
  <div class='nav-left'>
    <nav>
      <a routerLink='/home' [class.homePage]='isAtHome' [@toggleTextColor]='isAtTop'>Home</a>
      <a routerLink='/keyboards' [class.homePage]='isAtHome' [@toggleTextColor]='isAtTop'>Keyboards</a>
    </nav>
  </div>
  <div class='title'>
    <nav>
      <a routerLink='/home' [class.homePage]='isAtHome' [@toggleTextColor]='isAtTop'>MacGyver Made</a>
    </nav>
  </div>
  <div class='nav-right'>
    <nav>
      <a routerLink='/about' [class.homePage]='isAtHome' [@toggleTextColor]='isAtTop'>About</a>
      <a routerLink='/blog' [class.homePage]='isAtHome' [@toggleTextColor]='isAtTop'>Blogs</a>
    </nav>
  </div>
</div>
