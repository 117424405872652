<div class='splash'>
  <img src="../../../assets/galaxy.png">
  <div class='splash-text'>
    <h1>Hey, thanks for checking out my website!</h1>
    <p>There's not a lot here right now. This website is a work in progress and will serve as a base of operations
      for my future projects. Click on About if you want to learn more about me!
    </p>
  </div>
</div>
<div class='section-1'>

</div>
